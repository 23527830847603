import React, { useRef, useState } from "react";
import { NavLink, Link, useNavigate,useHistory } from "react-router-dom";
import useForm from "../../../hooks/use-form";
import { validateVesting } from "../../../core/validations/user-validations";
import useAlert from "./../../../hooks/use-alert";
import { vestingSubmit } from "../../../core/services/api/user";
const VestingForm = () => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
    const handleVestingSubmit = async () => {
        if (values.wallet_address !== '') {
          const result = await vestingSubmit(values);
          if (result && result.status) {
            showAlert("SUCCESS", "Success", result.message);
            if (result.message == 'Already updated') {
              showAlert("ERROR", "Error", result.message);
              /*
               * navigate('/vesting', {
               *   state: { message: result.message }
               * });
               */
            } else {
              navigate('/login', {
                state: { message: 'Thanks for the updating vesting details' }
              });
            }
          } else {
            showAlert("ERROR", "Error", result.message);
            setShowMessage(true);
          }
        }
      };
    const { values, errors, handleSubmit, handleChange } = useForm(
        handleVestingSubmit,
        validateVesting
      );
  return (
    <form onSubmit={handleSubmit}>
      <div className="log-box">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                placeholder="Email"
                maxLength={50}
                value={values.email || ''}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <input
                type="text"
                name="wallet_address"
                className={`form-control ${errors.wallet_address ? 'is-invalid' : ''}`}
                placeholder="Wallet Address"
                maxLength={50}
                value={values.wallet_address || ''}
                onChange={handleChange}
              />
              {errors.wallet_address && (
                <div className="invalid-feedback">{errors.wallet_address}</div>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
            <input
                    type="text"
                    name="otp"
                    className={`form-control ${errors.otp ? 'is-invalid' : ''}`}
                    placeholder="OTP"
                    maxLength={6} // Set the maximum length to 6
                    // pattern="\d{6}" // Use a pattern to enforce numeric characters and length
                    value={values.otp || ''}
                    onChange={handleChange}
                    />
              {errors.otp && (
                <div className="invalid-feedback">{errors.otp}</div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group pt-10">
        <button type="submit" className="buy-coin-btn w-100">
            Submit
            </button>
        </div>
      </div>
    </form>
  );
};

export default VestingForm;