import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/DcommLogo.png";
import { Col, Row } from "react-bootstrap";
import VestingForm from "../../components/shared/forms/vesting-from";
import { NavLink, Link } from "react-router-dom";

const VestingDetails = () => {
  useEffect(() => {
    document.title = "Vesting";
    localStorage.clear();
  }, []);
  return (
    <>
      <div className="login-page">
        <div className="flex-rows">
          <div className="column-img">
            <Row>
              <Col md={2}>
                <div className="logo">
                  <Link to={"/"}>
                    <img src={Logo} title="" alt="" />
                  </Link>
                </div>
              </Col>
            </Row>

          </div>
          <div className="column-text dcomm-login-form-layer">

            <VestingForm />

            <div className="d-flex justify-content-between">
              <div className="font-ffffff">
                If already verified please
              </div>
              <div>
                 <NavLink to={"/login"} className="font-ffffff">Log In</NavLink>
                 {" / "}
                 <NavLink to={"/signup"} className="font-ffffff">Register</NavLink>
               </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VestingDetails;