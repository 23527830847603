import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/DcommLogo.png";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
const VestingDone = () => {
  const location = useLocation();
  const message = location.state && location.state.message;
  useEffect(() => {
    document.title = "Vesting";
    localStorage.clear();
  }, []);
  return (
    <>
    <div className="login-page">
      <div className="flex-rows">
        <div className="column-img">
          <Row className="align-items-center justify-content-center full-height">
            <Col md={2} className="text-center">
              <div className="logo" style={{ color: 'White' }}>
                <p> {message} </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  );
};

export default VestingDone;