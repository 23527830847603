import "./App.css";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import { Suspense } from "react";
import ErrorPage from "./pages/public/error-page";
import Page404 from "./pages/public/page-404";
import Home from "./pages/public/home";
import SunsetNatureHome from "./pages/public/sunset-nature-home";
import SunsetEnergyHome from "./pages/public/sunset-energy-home";
import Investors from "./pages/public/investors";
import AboutUs from "./pages/public/about-us";
import Benefits from "./pages/public/benefits";
import FAQs from "./pages/public/faqs";
import Token from "./pages/public/token";
import Login from "./pages/public/login";
import VestingDetails from "./pages/public/vesting-feature";
import VestingDone from "./pages/public/vesting-done";
import SignUp from "./pages/public/sign-up";
import Dashboard from "./pages/private/dashboard";
import Reports from "./pages/private/reports";
import EnergyNft from "./pages/private/energynft";
import NatureNft from "./pages/private/naturenft";
import Redemptions from "./pages/private/redemptions";
import Orders from "./pages/private/orders";
import KYCVerification from "./pages/private/kyc_verification";
import TokenHoldings from "./pages/private/token_holdings";
import WalletAddress from "./pages/private/wallet-address";
import ProjectDetails from "./pages/public/project-details";
import PrivateRoute from "./hooks/private-route";
import PublicRoute from "./hooks/public-route";
import TermsAndConditions from "./pages/public/terms-and-conditions";
import PrivacyPolicy from "./pages/public/privacy-policy";
import ContactUs from "./pages/public/contact-us";
import ConfirmPayment from "./pages/private/confirmPayment";
import QRCode from "./pages/private/qrcode";
import SubmitOtp from "./pages/public/submit-opt";
import BootstrapToast from "./components/utils/alerts/bootstrap-toast";
import UserProfile from "./pages/private/userProfile";
import Header from "./components/shared/header";
import ProjectDetail from "./pages/public/project-detail";
import ProjectList from "./pages/public/project-list";
import NewsletterSubscribeHome from "./pages/public/newsletter-subscribe-home";
import UploadImage from "./pages/private/upload_image";
import ConfirmPasswordForm from "./pages/public/confirm-otp";
import CmsPage from "./pages/public/cms-page";
import ForgotPassword from "./pages/public/forgot-password";
import ResetPassword from "./pages/public/reset-password";
import VerifyOtp from "./pages/public/verify-otp";
import News from "./pages/public/news";
import Blog from "./pages/public/blog";
import BlogDetail from "./pages/public/blog-detail";
import DFender from "./pages/private/dFender";
import Club from "./pages/private/Club";
import AddDFenderPage from "./pages/private/addDfenderPage";
// import AddDClub from "./pages/private/hundredClub";
import FaqPage from "./pages/private/faqPage";
function App() {
  return (
    <>
      <BootstrapToast />
      <BrowserRouter>
        <BootstrapToast />
        {/* <Header /> */}
        <Suspense>
          <Routes>
            {/* All Public Routes */}
            <Route element={<PublicRoute />}>
              {/* <Route path="/error_page" element={<ErrorPage />} />
              <Route path="/nature_home" element={<SunsetNatureHome />} /> */}
              <Route path="/404" element={<Page404 />} />
              {/* <Route path="/about" element={<AboutUs />} /> */}
              {/* <Route path="/benefits" element={<Benefits />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/token" element={<Token />} />
              <Route path="/news" element={<News />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/blog-detail/:id" element={<BlogDetail />} />
              <Route
                path="/terms_and_conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/privacy_policy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/:pageType" element={<Home />} />
              <Route
                path="/newsletter/subscribe"
                element={<NewsletterSubscribeHome />}
              />

              <Route path="/project/:projectId" element={<ProjectDetail />} />
              <Route path="/project-list" element={<ProjectList />} />

            <Route path="/project_details" element={<ProjectDetails />} /> */}
              <Route exact path="/" element={<VestingDetails />} />
              {/* <Route path="/vesting" element={< VestingDone/>} /> */}
              <Route exact path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/verify-otp" element={<VerifyOtp />} />
              <Route path="/submit_otp" element={<SubmitOtp />} />
              <Route path="/confirm_otp" element={<ConfirmPasswordForm />} />
              <Route path="/page/:pageId" element={<CmsPage />} />
            </Route>
            <Route path="*" element={<Page404 />} />
            {/* All Private Routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/opt_verify" element={<QRCode />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user-profile" element={<UserProfile />} />
              {/* <Route path="/orders" element={<Reports />} /> */}
              {/* <Route path="/dfender" element={<DFender />} /> */}

              {/* <Route path="/energynft" element={<EnergyNft />} /> */}
              {/* <Route path="/buy-coin" element={<NatureNft />} /> */}
              {/* <Route path="/club" element={<Club />} /> */}
              {/* <Route path="/redemptions" element={<Redemptions />} />
              <Route path="/orders" element={<Orders />} />*/}
              <Route path="/payment/success" element={<ConfirmPayment />} />
              <Route path="/kyc-verification" element={<KYCVerification />} />
              <Route path="/wallet-address" element={<WalletAddress />} />
              {/* <Route path="/upload-image" element={<UploadImage />} /> */}
              <Route path="/update/user-profile" element={<UploadImage />} />
              <Route path="/add-dfender" element={<AddDFenderPage />} />
              {/* <Route path="/add-club" element={<AddDClub />} /> */}
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/holdings" element={<TokenHoldings />} />
            </Route>

            <Route render={() => <Navigate to="/404" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
